import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { headingTextStyle } from '@/components/styled/AboutStyled';
import { rem } from '@/app/common/style/functions';
import {
  desktopAndBelow,
  tabletAndBelow,
  phabletAndBelow,
} from '@/app/common/style/mixins';
import { h4, h5 } from '@/app/common/style/typography';
import { COLOR } from '@/app/common/style/variables';

const InvoicingsectionStyle = css`
  background-color: ${COLOR.woodSmoke5}; //$color-wood-smoke-5;

  .heading-text-style {
    ${headingTextStyle}
  }

  .section-header {
    h2 {
      margin-bottom: 56px;
      ${headingTextStyle}
    }
  }

  .invoicing-details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 60px;
    ${desktopAndBelow(css`
      grid-template-columns: repeat(2, 1fr);
    `)}

    .details {
      h3 {
        margin-bottom: 8px;
        ${h4}
        ${headingTextStyle}
      }

      h4 {
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 8px;
        ${h5}
        ${headingTextStyle}
      }

      p {
        font-size: ${rem(14)};
        line-height: 22px;
        margin-bottom: 0;
        color: ${COLOR.woodSmoke2}; // $color-wood-smoke-2;
      }
    }
  }

  ${tabletAndBelow(css`
    .section-header {
      h2 {
        margin-bottom: 32px;
      }
    }
  `)}

  ${phabletAndBelow(css`
    .invoicing-details {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 32px;
    }
  `)}
`;

const InvoicingSectionStyled = styled.div`
  ${InvoicingsectionStyle}
`;

export default InvoicingSectionStyled;
