import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ContentfulContentRawProp, BaseComponentProps } from '@/types';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import InvoicingSectionStyled from './InvoicingSectionStyled';

interface DataProps {
  contentfulBlock: {
    id: string;
    title: string;
    text: {
      text: string;
    };
    text2: {
      text2: string;
    };
    content: ContentfulContentRawProp;
    content2: ContentfulContentRawProp;
    content3: ContentfulContentRawProp;
  };
}

const query = graphql`
  query invoicing {
    contentfulBlock(pages: { eq: "about" }, slug: { eq: "about-invoicing" }) {
      id
      title
      text {
        text
      }
      text2 {
        text2
      }
      content {
        raw
      }
      content2 {
        raw
      }
      content3 {
        raw
      }
    }
  }
`;
const InvoicingSection: React.FC<BaseComponentProps> = () => {
  const data: DataProps = useStaticQuery(query);
  return (
    <InvoicingSectionStyled>
      <div className="invoicing-container container">
        <div className="section-header">
          <h2>{data.contentfulBlock.title}</h2>
        </div>

        <div className="invoicing-details">
          <div className="details main">
            <h3 className="company-name">{data.contentfulBlock.text.text}</h3>
            <p className="vat">{data.contentfulBlock.text2.text2}</p>
          </div>

          <div className="details">
            <ContentfulRawToComponent raw={data.contentfulBlock.content.raw} />
          </div>

          <div className="details">
            <ContentfulRawToComponent raw={data.contentfulBlock.content2.raw} />
          </div>

          <div className="details">
            <ContentfulRawToComponent raw={data.contentfulBlock.content3.raw} />
          </div>
        </div>
      </div>
    </InvoicingSectionStyled>
  );
};

export default InvoicingSection;
